import { useEffect, useRef, useState } from "react";

export const useIsSticky = () => {
  const ref = useRef<HTMLDivElement>(null);
  const [isSticky, setSticky] = useState(false);

  const handleScroll = () => {
    if (ref.current) {
      setSticky(ref.current.getBoundingClientRect().top <= 0);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", () => handleScroll);
    };
  });

  return { ref, isSticky };
};
