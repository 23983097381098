import { HeroWithImage } from "../../Hero";

export const HeroTextBuddy = () => (
  <HeroWithImage
    tagline="Transform text with ease"
    headline="Your Text Buddy in Figma"
    description="Select any Figma text node and use TextBuddy to transform your text with ease. Change text length or improve text quality. Upgrade your text game and streamline your Figma workflow with TextBuddy - starting at no costs!"
    src="/images/text-mate/text-buddy.png"
    srcDark="/images/text-mate/text-buddy.png"
    alt="Preview of TextMate's TextBuddy feature"
    imagePosition="left"
  />
);
