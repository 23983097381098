import type { StackProps } from "@chakra-ui/react";
import { Heading, Image, Stack, Text } from "@chakra-ui/react";

type HeroProps = {
  tagline?: string;
  headline: string;
  description: string | JSX.Element;
  withAccent?: boolean;
  mainHeading?: boolean;
} & StackProps;
export const Hero4xl = (props: HeroProps) => {
  const {
    tagline,
    headline,
    description,
    withAccent,
    mainHeading,
    ...stackProps
  } = props;
  return (
    <Stack spacing="32px" alignItems="start" {...stackProps}>
      <Stack spacing="16px">
        <Text
          textStyle={{ base: "sm", md: "md" }}
          fontWeight="semibold"
          color={withAccent ? "fg.accent.default" : "fg.default"}
        >
          {tagline}
        </Text>
        <Heading
          textStyle={{ base: "xl", md: "3xl" }}
          color={withAccent ? "fg.accent.default" : "fg.default"}
          as={mainHeading ? "h1" : undefined}
        >
          {headline}
        </Heading>
        <Text
          textStyle={{ base: "md", md: "lg" }}
          color={withAccent ? "fg.accent.muted" : "fg.muted"}
        >
          {description}
        </Text>
      </Stack>
    </Stack>
  );
};

type HeroWithImageBelowProps = Omit<HeroProps, "children"> & {
  src: string;
  alt: string;
};
export const HeroWithImageBelow = (props: HeroWithImageBelowProps) => {
  const { tagline, headline, description, src, alt } = props;
  return (
    <Hero4xl {...{ tagline, headline, description }}>
      <Image objectFit="cover" src={src} alt={alt} />
    </Hero4xl>
  );
};
