import { Heading, Text, VStack } from "@chakra-ui/react";

import { Section } from "./layout/Section";

export const meta = () => ({ title: "Impressum" });

export default function ImprintPage() {
  return (
    <Section>
      <VStack spacing="8" alignItems="start">
        <Heading size="md" width="full">
          Imprint
        </Heading>
        <Text>
          Details according to § 5 TMG Simon Holzmayer Auer Str. 70D, 76227
          Karlsruhe Represented by: Simon Holzmayer
        </Text>
        <Heading size="sm">Kontakt:</Heading>
        <Text>
          E-Mail: hello@uikit.pro Responsible for the content according to § 55
          Abs. 2 RStV: Simon Holzmayer Auer Str. 70D, 76227 Karlsruhe
        </Text>
        <Heading size="md">Haftungsausschluss</Heading>
        <Heading size="sm">Haftung für Inhalte</Heading>
        <Text>
          The contents of our pages were created with the utmost care. For the
          accuracy, completeness and timeliness of the contents, we can take
          over no guarantee. As a service provider we are responsible according
          to § 7 1 TMG (Telemedia Act) for own contents on these pages according
          to the general responsible for our own content on these pages.
          According to §§ 8 to 10 TMG, we are as a service service provider, we
          are not obligated to monitor transmitted or stored to monitor
          transmitted or stored third-party information or to investigate
          circumstances or to investigate circumstances that indicate illegal
          activity. Obligations to remove or block the use of information
          according to the general laws remain unaffected. However, any
          liability in this respect is only possible from the time of knowledge
          of a concrete violation of the law. If we become aware of
          infringements of the law, we will remove this content immediately.
          remove them immediately. Translated with www.DeepL.com/Translator
          (free version)
        </Text>

        <Heading size="sm">Urheberrecht</Heading>
        <Text>
          The contents and works on these pages created by the site operators
          are pages are subject to German copyright law. The duplication,
          distribution and any kind of exploitation outside the limits of the
          copyright the limits of copyright require the written consent of the
          respective author or author or creator. Downloads and copies of this
          site are only permitted for private, non-commercial use. Insofar as
          the content on this site was not created by the operator the
          copyrights of third parties are respected. In particular contents of
          third parties are marked as such. Should you nevertheless become aware
          of copyright infringement, we ask for an appropriate reference.
          appropriate notice. If we become aware of any infringements of the law
          we will remove such content immediately.
        </Text>
        <Heading size="sm">Privacy</Heading>
        <Text>
          The use of our website is generally possible without providing
          personal data. As far as on our sides personal data (such as name,
          address or e-mail addresses) e-mail addresses) is collected, this is
          done, as far as possible, on a voluntary basis. on a voluntary basis.
          This data will not be passed on to third parties without your to third
          parties without your express consent. We point out that data
          transmission on the Internet (eg communication by e-mail) may be
          e-mail) can have security gaps. A complete protection of the data from
          access by third parties is not possible. The use of contact data
          published within the framework of the imprint obligation by third
          third parties for the purpose of sending unsolicited advertisements
          and and information material is hereby expressly prohibited. The
          operators of the pages expressly reserve the right to take legal steps
          in the case of unsolicited sending of advertising information, for
          example by spam mails.
        </Text>
      </VStack>
    </Section>
  );
}
