import { Box, Image, Stack, useColorModeValue } from "@chakra-ui/react";
import { Hero } from "../../../Hero";
import { AccentToCanvas } from "../../layout/slanter/AccentToCanvas";
import { CanvasToAccent } from "../../layout/slanter/CanvasToAccent";
import { PriceCard } from "./PriceCard";

export const Pricing = () => (
  <Box id="pricing">
    <AccentToCanvas />
    <Hero
      tagline="Pricing"
      headline="Get it now"
      description="Get the UIKit Pro now and unlock the full potential of your design process."
      withAccent
      maxW="100%"
    >
      <Stack
        direction="row"
        justify={{ base: "center", md: "space-between" }}
        align="center"
        spacing={{ base: "unset", md: "16" }}
        maxW="100%"
      >
        <Stack flex="1" display={{ base: "none", md: "block" }}>
          <Image
            src={`/images/figma-kit/preview-pricing-${useColorModeValue(
              "light",
              "dark"
            )}.png`}
            alt="Preview of some components"
            objectFit="cover"
            boxSize="full"
          />
        </Stack>
        <PriceCard />
      </Stack>
    </Hero>
    <CanvasToAccent />
  </Box>
);
