import {
  Box,
  Container,
  Heading,
  ListItem,
  Stack,
  Text,
  UnorderedList,
} from "@chakra-ui/react";
import { Hero } from "../Hero";

const LicensePage = () => {
  return (
    <Box boxSize="full">
      <Container justifyContent="center">
        <Hero
          tagline="valid since January 2023"
          headline="License Agreement"
          description={`This License Agreement (the "Agreement") is a legal agreement between you (the "Licensee") and UIKit Pro (the "Licensor"), for the use of the Figma UIKit Pro, the Themes or other assets (the "Software").`}
          children={undefined}
          width="full"
          mainHeading
        />
        <Stack spacing="8">
          <Stack alignItems="center">
            <Heading as="h2" size="md">
              LICENSE GRANT
            </Heading>
            <Text maxW="3xl">
              The Licensor grants to the Licensee a non-exclusive,
              non-transferable license to use the Software for the Licensee's
              personal or commercial purposes, subject to the terms and
              conditions of this Agreement.
            </Text>
          </Stack>

          <Stack alignItems="center">
            <Heading as="h2" size="md">
              RESTRICTIONS
            </Heading>
            <Text maxW="3xl">
              The Licensee shall not: a. Reverse engineer, decompile,
              disassemble, or attempt to discover the source code of the
              Software; b. Modify, adapt, translate, or create derivative works
              based on the Software; c. Sell, rent, lease, or sublicense the
              Software to any third party; d. Remove or alter any copyright,
              trademark, or other proprietary notices on or in the Software.
            </Text>
          </Stack>

          <Stack alignItems="center">
            <Heading as="h2" size="md">
              Intellectual Property
            </Heading>
            <Text maxW="3xl">
              The Software and all intellectual property rights related to the
              Software, including but not limited to copyrights, trademarks or
              trade secrets are the property of the Licensor.
            </Text>
          </Stack>

          <Stack alignItems="center">
            <Heading as="h2" size="md">
              Disclaimer of Warranty
            </Heading>
            <Text maxW="3xl">
              The software is provided 'as is,' without any kind of warranty,
              whether express or implied, including but not limited to the
              warranties of merchantability, fitness for a particular purpose,
              and non-infringement. The licensor does not guarantee that the
              software will meet the licensee's requirements or that its
              operation will be uninterrupted or error-free.
            </Text>
          </Stack>

          <Stack alignItems="center">
            <Heading as="h2" size="md">
              Limitation of Liability
            </Heading>
            <Text maxW="3xl">
              The licensor shall not be held responsible for any damages,
              including but not limited to direct, indirect, special,
              incidental, or consequential damages, that may arise from the use
              or inability to use the software, even if the licensor has been
              informed of the possibility of such damages.
            </Text>
          </Stack>

          <Stack alignItems="center">
            <Heading as="h2" size="md">
              Termination
            </Heading>
            <Text maxW="3xl">
              This Agreement will terminate automatically if the Licensee
              breaches any of its terms and conditions. Upon termination, the
              Licensee shall immediately cease using the Software and shall
              destroy all copies of the Software in its possession.
            </Text>
          </Stack>

          <Stack alignItems="center">
            <Heading as="h2" size="md">
              Governing law
            </Heading>
            <Text maxW="3xl">
              This Agreement shall be governed by and construed in accordance
              with the laws of [Insert Your Country Name], without giving effect
              to any choice or conflict of law provision or rule.
            </Text>
          </Stack>

          <Stack alignItems="center">
            <Heading as="h2" size="md">
              Entire Agreement
            </Heading>
            <Text maxW="3xl">
              This Agreement constitutes the entire agreement between the
              Licensee and the Licensor with respect to the Software and
              supersedes all prior or contemporaneous communications and
              proposals, whether oral or written, between the parties concerning
              the Software. By downloading or by using the Software, the
              Licensee agrees to be bound by the terms and conditions of this
              Agreement.
            </Text>
          </Stack>

          <Stack alignItems="center">
            <Heading as="h2" size="md">
              You are allowed to:
            </Heading>
            <Text maxW="3xl">
              This license permits you to use the Software in various ways, as
              outlined below:
              <UnorderedList>
                <ListItem>
                  End Product Creation: You can create an End Product for a
                  client, as well as for personal or commercial use as long as
                  the Figma UI Kit or source code is not published.
                </ListItem>
                <ListItem>
                  Multi-Use: This is a 'multi-use' license, which means you may
                  use a Software multiple times, in multiple projects.
                </ListItem>
                <ListItem>
                  Team Library and Organisation Sharing: You may publish the
                  Software in your team library or share the files within your
                  organization for participants only if the Figma UIKit or
                  source code is not publically available.
                </ListItem>
              </UnorderedList>
            </Text>
          </Stack>

          <Stack alignItems="center">
            <Text maxW="3xl">
              Examples of usage allowed by the license include:
              <UnorderedList>
                <ListItem>Creating websites for yourself or a client.</ListItem>
                <ListItem>
                  Creating web applications or apps for yourself or a client.
                </ListItem>
                <ListItem>
                  Creating commercial SaaS applications or websites for end
                  users who pay to use as a subscription.
                </ListItem>
                <ListItem>
                  Creating commercial SaaS applications or websites for end
                  users who pay a one-time fee to use.
                </ListItem>
              </UnorderedList>
            </Text>
          </Stack>

          <Stack alignItems="center">
            <Stack alignItems="center">
              <Heading as="h2" size="md">
                You are NOT allowed to:
              </Heading>
              <Text maxW="3xl">
                This license forbids you to use the Software in various ways, as
                outlined below:
                <UnorderedList>
                  <ListItem>
                    Sharing Restrictions: You are not allowed to share the
                    Software link on the web or distribute it through Figma's
                    public access feature.
                  </ListItem>
                  <ListItem>
                    End Product Restrictions: You cannot include any of the
                    Softwares design files in the End Product as they are or
                    publish the source code publically.
                  </ListItem>
                  <ListItem>
                    Redistribution Restrictions: You cannot redistribute the
                    Software. This includes creating a template or library for
                    another platform or software using the Software, either on
                    its own or bundled with others, even if you modify the
                    Software.
                  </ListItem>
                  <ListItem>
                    Availability Restrictions: You cannot re-distribute or make
                    the Software available as it is or with superficial
                    modifications.
                  </ListItem>
                </UnorderedList>
              </Text>
            </Stack>
            <Stack alignItems="center">
              <Text maxW="3xl">
                Examples of usage not allowed by the license:
                <UnorderedList>
                  <ListItem>
                    Online Publishing Restrictions: You are not allowed to share
                    or publish the files or assets within the files online or
                    make them available publicly.
                  </ListItem>
                  <ListItem>
                    Creating commercial SaaS applications or websites for end
                    users who pay to use as a subscription.
                  </ListItem>
                  <ListItem>
                    App or Website Builder Restrictions: You cannot create an
                    "app builder" or "website builder" that uses components,
                    designs, assets, or templates from UIKit Pro (even if
                    modified).
                  </ListItem>
                </UnorderedList>
              </Text>
            </Stack>
          </Stack>
        </Stack>
      </Container>
    </Box>
  );
};

export default LicensePage;
