import { HeroWithImage } from "../../Hero";

export const HeroTheme = () => (
  <HeroWithImage
    tagline="Flexible Theme Control"
    headline="Switch style in seconds"
    description="Unlock endless design possibilities with UIKit Pro's 3 included
        themes, all fully supporting both dark and light mode. Elevate your
        project with effortless customization"
    src="/images/figma-kit/hero-themes.png"
    alt="A preview of UIKit Pro's 3 included themes"
  />
);
