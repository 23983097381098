import type { ContainerProps } from "@chakra-ui/react";
import { Box, Container } from "@chakra-ui/react";
import type { ForwardedRef } from "react";
import { forwardRef } from "react";

export const Section = forwardRef(
  (props: ContainerProps, ref: ForwardedRef<HTMLDivElement>) => {
    const { children, ...containerProps } = props;
    return (
      <Container
        as="section"
        mx="auto"
        maxW="unset"
        display="flex"
        justifyContent="center"
        alignItems="center"
        px={{ base: "4", md: "6", lg: "8" }}
        ref={ref}
        {...containerProps}
      >
        <Box maxW="3xl" w={{ base: "full", md: "5xl" }}>
          {children}
        </Box>
      </Container>
    );
  }
);
Section.displayName = "Section";
