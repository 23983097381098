import { Box, Button, Link, Stack, Text } from "@chakra-ui/react";
import { Hero4xl } from "../../../Hero4xl";
import { AccentToCanvas } from "../../layout/slanter/AccentToCanvas";
import { CanvasToAccent } from "../../layout/slanter/CanvasToAccent";
import { PriceCard } from "./PriceCard";
import { Link as RouterLink } from "react-router-dom";
export const Pricing = () => (
  <Box bg="accent" id="pricing">
    <AccentToCanvas />

    <Stack
      direction={{ base: "column", md: "row" }}
      justify={{ base: "center", md: "space-between" }}
      align="center"
      spacing={{ base: "16", md: "16" }}
      padding={{ base: "4", lg: "64px" }}
      paddingY={{ base: "8", lg: "64px" }}
      maxW="100%"
    >
      <Hero4xl
        tagline="Pricing"
        headline="Get it now"
        description={
          <Text>
            Our early access program currently does not offer any paid versions.
            The free pricing is set to remain indefinitely. However, if you
            would like to support us, check out{" "}
            <Link
              as={RouterLink}
              to="/"
              color="fg.accent.default"
              borderBottom="1px"
              _hover={{
                textDecoration: "none",
                borderBottom: "2px",
              }}
            >
              Figma UIKit Pro
            </Link>
            , which is a component library that can help you design production
            ready designs even faster.
          </Text>
        }
        withAccent
        maxW="100%"
        flex="1"
      />
      <PriceCard
        description="Effortlessly transform text"
        features={[
          "30 daily transformations",
          "All features included",
          "TextBuddy integration",
          "TextTokens integration",
        ]}
        title="Free"
        price="$0"
        buyLink={
          <Button
            size="xl"
            as={Link}
            href="https://www.figma.com/community/plugin/1209233052747647603/TextMate"
          >
            Begin now
          </Button>
        }
      />
    </Stack>
    <CanvasToAccent />
  </Box>
);
