import { Icon, Stack, Text } from "@chakra-ui/react";
import { FiCheck } from "react-icons/fi";

export const PriceFeature = (props: { feature: string }) => {
  return (
    <Stack direction="row" justify="flex-start" align="center" spacing="16px">
      <Icon as={FiCheck} color="fg.default" />
      <Text color="accent" textStyle="md">
        {props.feature}
      </Text>
    </Stack>
  );
};
