import { HeroWithImage } from "../../Hero";

export const HeroTextTokens = () => (
  <HeroWithImage
    tagline="Effortlessly update text everywhere"
    headline="Change text once - update everywhere"
    description="With Text Tokens, you can store text in the plugin and assign it to text nodes in your Figma file. Update all instances of that text with just one click, simplifying your text updates and saving you time. Take control of your design workflow and upgrade to Text Tokens today - totally free!"
    src="/images/text-mate/text-tokens.png"
    srcDark="/images/text-mate/text-tokens.png"
    alt="A preview of TextMate's TextTokens feature"
    imagePosition="right"
  />
);
