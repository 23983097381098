import { HeroWithImage } from "../../Hero";

export const HeroDeveloper = () => (
  <HeroWithImage
    tagline="Developer first"
    headline="Speed up your development process"
    description="All components are designed with the developer in mind, offering
  effortless implementation. FigPilot Ready, taking your development
  process to new heights of efficiency."
    src="/images/figma-kit/hero-developer.png"
    alt="Preview of UIKit Pro's code generation feature"
  />
);
