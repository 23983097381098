import { Link, Stack } from "@chakra-ui/react";
import { NavLink as RouterLink } from "react-router-dom";

export const Footer = () => (
  <Stack
    padding={{ base: "4", lg: "64px" }}
    paddingTop="92px"
    alignSelf="stretch"
    justify="flex-start"
    align="flex-start"
    spacing="10px"
    overflow="hidden"
  >
    <Stack
      justify="flex-start"
      align="flex-start"
      spacing="16px"
      alignSelf="stretch"
    >
      <Stack
        direction="row"
        justify="space-between"
        align="center"
        spacing="64px"
        alignSelf="stretch"
      >
        <Link href="mailto:hello@uikit.pro" textStyle="md">
          hello@uikit.pro
        </Link>
        <Stack direction="row" justify="center" align="center" spacing="8">
          <Stack
            paddingY="8px"
            direction="row"
            justify="flex-start"
            align="flex-start"
            spacing="2"
            overflow="hidden"
          >
            <Link as={RouterLink} to="/license" textStyle="md">
              License
            </Link>
            <Link as={RouterLink} to="/imprint" textStyle="md">
              Imprint
            </Link>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  </Stack>
);
