import { ChakraProvider } from "@chakra-ui/react";
// @ts-ignore - hack as the javascript has no d.ts files
import { theme } from "@uikit-pro/theme-black-and-white";
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";
import { ScrollToTop } from "./basic/ScrollToTop";
import ImprintPage from "./routes/ImprintPage";
import IndexPage from "./routes/IndexPage/index";
import { SiteLayout } from "./routes/layout/SiteLayout";
import LicensePage from "./routes/LicensePage";
import TextMatePage from "./routes/TextMatePage";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <BrowserRouter>
        <SiteLayout boxSize="full">
          <ScrollToTop />
          <Routes>
            <Route path="/" element={<IndexPage />} />
            <Route path="/text-mate" element={<TextMatePage />} />
            <Route path="/imprint" element={<ImprintPage />} />
            <Route path="/license" element={<LicensePage />} />
          </Routes>
          <Outlet />
        </SiteLayout>
      </BrowserRouter>
    </ChakraProvider>
  </React.StrictMode>
);
