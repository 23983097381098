import type { ContainerProps } from "@chakra-ui/react";
import { Box, Stack, useColorModeValue, useToken } from "@chakra-ui/react";
import { Footer } from "./footer/Footer";
import { Navbar } from "./navbar/Navigation";

export const SiteLayout = (props: ContainerProps) => {
  const [bgAccent, bgAccentMuted] = useToken("colors", [
    useColorModeValue("blackAlpha.900", "whiteAlpha.500"),
    useColorModeValue("blackAlpha.500", "whiteAlpha.100"),
  ]);

  return (
    <Stack
      maxWidth="100%"
      minH="100vh"
      background={`linear-gradient(113deg, ${bgAccent} 0%, ${bgAccentMuted} 100%)`}
      backgroundRepeat="no-repeat"
      backgroundPosition="right"
      position="relative"
      paddingY={{ base: "unset", sm: "8" }}
      paddingX={{ base: "unset", sm: "10" }}
    >
      <Stack
        position="sticky"
        top="0"
        justify="flex-start"
        align="flex-start"
        spacing="0px"
        maxWidth={{ base: "100%", sm: "6xl" }}
        background="bg.canvas"
        transition="all 0.3s ease-in-out"
        boxShadow={useColorModeValue("xl", "xl-dark")}
        minH={{ base: "100vh", sm: "calc(100vh - 64px)" }}
        borderTopRadius={{ base: "unset", sm: "16px" }}
      >
        <Navbar />
        <Box {...props} maxW="100%" flex="1" />
        <Footer />
      </Stack>

      <Stack
        position="absolute"
        top="0"
        bottom="0"
        left="430px"
        right="0"
        height="100%"
        zIndex="-1"
        filter="blur(4px)"
        backgroundImage="url('/images/background-light.png')"
        backgroundRepeat="no-repeat"
        backgroundPosition="right"
        backgroundSize="cover"
      ></Stack>
    </Stack>
  );
};
