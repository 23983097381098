import {
  Avatar,
  Button,
  Heading,
  HStack,
  Link,
  Stack,
  Text,
} from "@chakra-ui/react";
import { FadeInImage } from "../../basic/FadeInImage";

type PageHeroProps = {
  title: string;
  description: string;
  buttonLabel: string;
  buttonOnClick: () => void;
  imageSrc: string;
  imageAlt: string;
  imageWidth: string;
  imageHeight: string;
  secondaryButtonLabel?: string;
  secondaryButtonOnClick?: () => void;
};
export const PageHero = (props: PageHeroProps) => (
  <Stack
    paddingStart={{ base: "4", lg: "64px" }}
    paddingEnd={{ base: "4", lg: "0" }}
    paddingY={{ base: "108px", lg: "unset" }}
    direction="row"
    justify="center"
    align="center"
    spacing="64px"
    maxWidth="100%"
  >
    <Stack
      justify="center"
      maxW="100%"
      align="flex-start"
      spacing="32px"
      flex="1"
    >
      <Stack
        justify="flex-start"
        align="flex-start"
        spacing="16px"
        alignSelf="stretch"
      >
        <Heading as="h1" lineHeight="1" textStyle={{ base: "6xl", md: "5xl" }}>
          {props.title}
        </Heading>
        <Text textStyle={{ base: "lg", md: "xl" }} color="fg.muted">
          {props.description}
        </Text>
      </Stack>
      <Stack
        direction={{ base: "column", md: "row" }}
        justify="flex-start"
        align="flex-start"
        spacing="10px"
        boxSize="full"
      >
        <Button
          size="xl"
          variant="primary"
          width={{ base: "100%", md: "unset" }}
          className="plausible-event-name=Figma+Hero+Get"
          onClick={props.buttonOnClick}
        >
          {props.buttonLabel}
        </Button>
        <Button
          as="a"
          size="xl"
          variant="outline"
          cursor="pointer"
          width={{ base: "full", md: "unset" }}
          onClick={props.secondaryButtonOnClick}
          className="plausible-event-name=Figma+Hero+Preview"
          target="_blank"
        >
          {props.secondaryButtonLabel}
        </Button>
      </Stack>
      <HStack spacing="4">
        <Avatar
          name="Simon Holzmayer"
          src="https://avatars.githubusercontent.com/u/20296626?v=4"
          size="sm"
        ></Avatar>{" "}
        <Text textStyle="lg" color="fg.muted">
          Created by <Link href="https://github.com/sholzmayer">Simon</Link>
        </Text>
      </HStack>
    </Stack>
    <FadeInImage
      display={{ base: "none", lg: "flex" }}
      justify="center"
      maxW="md"
      minH="md"
      height={props.imageHeight}
      width={props.imageWidth}
      src={props.imageSrc}
      alt="Preview of the components in UIKit Pro"
    />
  </Stack>
);
