import { IconButton, useColorMode } from "@chakra-ui/react";
import { FiMoon, FiSun } from "react-icons/fi";

export const ColorModeToggle = () => {
  const { toggleColorMode } = useColorMode();
  return (
    <IconButton
      onClick={toggleColorMode}
      icon={<SunMoonIcon />}
      aria-label="Toggle color mode"
      variant="ghost"
    />
  );
};

const SunMoonIcon = () => {
  const { colorMode } = useColorMode();
  return colorMode === "light" ? <FiMoon /> : <FiSun />;
};
