import { chakra, Stack, StackProps } from "@chakra-ui/react";
import { motion } from "framer-motion";
import { useState } from "react";

const MotionImage = chakra(motion.img, {
  shouldForwardProp: () => true,
});

type FadeInImageProps = {
  height: string;
  width: string;
  src: string;
  alt: string;
} & StackProps;

export const FadeInImage = (props: FadeInImageProps) => {
  const [imageLoading, setImageLoading] = useState(true);

  const imageLoaded = () => {
    setImageLoading(false);
  };

  const { height, width, src, ...stackProps } = props;
  return (
    <Stack maxW="md" height={height} width={width} {...stackProps}>
      <MotionImage
        src={src}
        alt="Preview of the components in UIKit Pro"
        initial={{ opacity: 0 }}
        animate={{
          opacity: imageLoading ? 0 : 1,
        }}
        // @ts-ignore
        transition={{ duration: 0.6 }}
        onLoad={imageLoaded}
        style={{ objectFit: "cover" }}
      />
    </Stack>
  );
};
