import { HeroTextBuddy } from "./HeroTextBuddy";
import { HeroTextTokens } from "./HeroTextTokens";
import { Pricing } from "./pricing/Pricing";
import { TextMatePageHero } from "./TextMatePageHero";

export default function TextMatePage() {
  return (
    <>
      <TextMatePageHero />
      <HeroTextBuddy />
      <HeroTextTokens />
      <Pricing />
    </>
  );
}
