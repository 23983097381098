import { HeroWithImage } from "../../Hero";

export const HeroComponentsGrid = () => (
  <HeroWithImage
    tagline="Your next use case is covered"
    headline="Various components for your needs"
    description="Get access to a wide range of components with UIKit Pro to build your project effortlessly. From app, marketing to e-commerce components, our Figma UI Kit has got you covered. Say goodbye to the hassle of starting from scratch and focus on creating impactful designs."
    src="/images/figma-kit/hero-components-grid.png"
    alt="The image showcases some UIKit Pro's components."
  />
);
