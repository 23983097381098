import {
  chakra,
  Container,
  HStack,
  Link,
  shouldForwardProp,
} from "@chakra-ui/react";
import { NavLink as RouterNavLink, Link as RouterLink } from "react-router-dom";
import { isValidMotionProp, motion } from "framer-motion";
import { ColorModeToggle } from "../ColorModeToggle";
import { Logo } from "./Logo";
import { useIsSticky } from "./useIsSticky";

const ChakraBox = chakra(motion.div, {
  /**
   * Allow motion props and non-Chakra props to be forwarded.
   */
  shouldForwardProp: (prop) =>
    isValidMotionProp(prop) || shouldForwardProp(prop),
});

export const Navbar = () => {
  const { isSticky, ref } = useIsSticky();

  return (
    <ChakraBox
      ref={ref}
      // @ts-ignore no problem in operation, although type error appears.
      transition={{
        duration: 1,
        type: "spring",
      }}
      width="100%"
      position="sticky"
      top="0"
      background="fg.accent.default"
      zIndex="banner"
      borderBottom="1px solid"
      borderColor="accent"
      borderTopRadius={{ base: "unset", sm: isSticky ? "unset" : "16px" }}
    >
      <Container
        py="4"
        paddingX={{ base: "4", lg: "64px" }}
        borderTopRadius={{ base: "unset", sm: "16px" }}
        overflow="hidden"
      >
        <HStack spacing="6" justifyContent="space-between">
          <Link as={RouterLink} to="/">
            <Logo />
          </Link>
          <HStack spacing={{ base: "0", md: "8" }}>
            <HStack spacing={{ base: "2", md: "4" }}>
              <Link
                as={RouterNavLink}
                to="/"
                _activeLink={{ fontWeight: "semibold" }}
                textAlign="center"
              >
                {/* <Link href="/" fontWeight="semibold"> */}
                Figma Kit
              </Link>
              <Link
                as={RouterNavLink}
                to="/text-mate"
                _activeLink={{ fontWeight: "semibold" }}
                textAlign="center"
              >
                TextMate Plugin
              </Link>
            </HStack>
            <ColorModeToggle />
          </HStack>
        </HStack>
      </Container>
    </ChakraBox>
  );
};
