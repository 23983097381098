import { useColorModeValue } from "@chakra-ui/react";
import { PageHero } from "../layout/PageHero";

export const TextMatePageHero = () => (
  <PageHero
    title="TextMate Figma Plugin"
    description="Get text editing in Figma simplified and improved with Text Mate. With features like Text Buddy and Text Tokens, it's the ultimate Figma plugin. And the cherry on top? It's completely free."
    buttonLabel="Why it's free?"
    buttonOnClick={() =>
      document
        .querySelector("#pricing")
        ?.scrollIntoView?.({ behavior: "smooth" })
    }
    imageAlt="TextMate Figma Plugin"
    imageSrc={`/images/text-mate/hero-${useColorModeValue(
      "light",
      "dark"
    )}.png`}
    imageHeight="325px"
    imageWidth="385px"
    secondaryButtonLabel="See TextMate"
    secondaryButtonOnClick={() => window.open("/textmate-preview", "_blank")}
  />
);
