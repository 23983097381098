import {
  Button,
  Divider,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { PriceFeature } from "./PriceFeature";

export const PriceCard = () => {
  return (
    <Stack
      padding="64px"
      width="sm"
      maxWidth="100%"
      justify="space-between"
      align="flex-start"
      spacing="32px"
      overflow="hidden"
      borderRadius="xl"
      boxShadow={useColorModeValue("xl", "xl-dark")}
      bg="fg.accent.default"
    >
      <Stack spacing="16px" alignSelf="stretch">
        <Stack spacing="16px" alignSelf="stretch">
          <Stack
            justify="center"
            align="center"
            spacing="15px"
            alignSelf="stretch"
          >
            <Text
              textStyle="5xl"
              fontWeight="bold"
              textAlign="center"
              color="accent"
            >
              $99
            </Text>
            <Stack justify="center" align="center">
              <Text
                color="fg.accent.emphasized"
                fontWeight="bold"
                textStyle="xl"
              >
                UI Kit PRO
              </Text>
              <Text color="fg.accent.emphasized" textStyle="md">
                Unlock design potential
              </Text>
            </Stack>
          </Stack>
          <Divider orientation="horizontal" />
        </Stack>
        <Text
          color="on-fg.accent.emphasized"
          fontWeight="semibold"
          textStyle="lg"
        >
          Includes
        </Text>
        <Stack>
          <PriceFeature feature="Over 250 UI elements" />
          <PriceFeature feature="Dark and light mode" />
          <PriceFeature feature="Regular updates" />
          <PriceFeature feature="Premium support" />
        </Stack>
      </Stack>
      <Button
        size="xl"
        as="a"
        href="/get-uikit-pro"
        data-gumroad-single-product="true"
        className="plausible-event-name=Figma+Pricing+Buy"
      >
        Get UIKit Pro
      </Button>
    </Stack>
  );
};
