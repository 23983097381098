import { HeroComponentsGrid } from "./HeroComponentsGrid";
import { HeroDeveloper } from "./HeroDeveloper";
import { HeroTheme } from "./HeroTheme";
import { IndexPageHero } from "./IndexPageHero";
import { Pricing } from "./pricing/Pricing";

export const meta = () => ({ title: "UIKit Pro" });

export default function IndexPage() {
  return (
    <>
      <IndexPageHero />
      <HeroComponentsGrid />
      <HeroTheme />
      <HeroDeveloper />
      <Pricing />
    </>
  );
}
