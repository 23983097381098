import { Stack, useToken } from "@chakra-ui/react";

export const AccentToCanvas = () => {
  const [bgAccent] = useToken("colors", ["bg.canvas"]);

  return (
    <Stack
      alignSelf="stretch"
      justify="flex-start"
      align="flex-start"
      bg="accent"
      boxSize="100%"
    >
      <svg
        width="100%"
        viewBox="0 0 1179 75"
        fill={bgAccent}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M 0 0 C 61.0103101288116 75 586.937905274698 43 586.937905274698 43 C 586.937905274698 43 1042.0069089332412 -11 1179 75 L 1179 0 L 0 0 Z" />
      </svg>
    </Stack>
  );
};
