import { useColorModeValue } from "@chakra-ui/react";
import { PageHero } from "../layout/PageHero";

export const IndexPageHero = () => (
  <PageHero
    title="The Ultimate UI Design Kit for Figma"
    description="Unlock the full potential of your design process with UIkit Pro. Elevate your design process with over 250 customizable UI elements, premium support, developer-friendly and compatible with Chakra UI."
    buttonLabel="Get UIKit Pro"
    buttonOnClick={() =>
      document
        .querySelector("#pricing")
        ?.scrollIntoView?.({ behavior: "smooth" })
    }
    imageAlt="UIKit Pro"
    imageSrc={`/images/figma-kit/preview-hero-${useColorModeValue(
      "light",
      "dark"
    )}.png`}
    imageHeight="844px"
    imageWidth="477px"
    secondaryButtonLabel="See UIKit Pro"
    secondaryButtonOnClick={() => window.open("/figma-preview", "_blank")}
  />
);
