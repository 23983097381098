import {
  Box,
  Heading,
  Image,
  Stack,
  StackProps,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import type { ReactNode } from "react";

type HeroProps = {
  tagline?: string;
  headline: string;
  description: string;
  children: ReactNode;
  withAccent?: boolean;
  mainHeading?: boolean;
  imagePosition?: "left" | "right" | "bottom";
} & StackProps;
export const Hero = (props: HeroProps) => {
  const {
    tagline,
    headline,
    description,
    children,
    withAccent,
    mainHeading,
    ...stackProps
  } = props;

  const direction = {
    right: "row" as const,
    left: "row-reverse" as const,
    bottom: "column" as const,
  }[props.imagePosition ?? "bottom"];

  return (
    <Stack
      padding={{ base: "4", lg: "64px" }}
      paddingY={{ base: "8", lg: "64px" }}
      maxWidth="full"
      justify="center"
      align="center"
      spacing="16px"
      bg={withAccent ? "accent" : "bg.canvas"}
      {...stackProps}
    >
      <Stack
        spacing="32px"
        maxWidth="full"
        alignItems="center"
        direction={{ base: "column", lg: direction }}
      >
        <Stack maxWidth={{ base: "100%", lg: "3xl" }} spacing="16px">
          {tagline && (
            <Text
              textStyle={{ base: "sm", md: "md" }}
              fontWeight="semibold"
              textAlign={{ base: "start", sm: "center" }}
              color={withAccent ? "fg.accent.default" : "fg.default"}
            >
              {tagline}
            </Text>
          )}
          <Heading
            textStyle={{ base: "6xl", md: "5xl" }}
            color={withAccent ? "fg.accent.default" : "fg.default"}
            textAlign={{ base: "start", sm: "center" }}
            as={mainHeading ? "h1" : undefined}
          >
            {headline}
          </Heading>
          <Text
            textStyle={{ base: "lg", md: "xl" }}
            color={withAccent ? "fg.accent.muted" : "fg.muted"}
            textAlign={{ base: "start", sm: "center" }}
          >
            {description}
          </Text>
        </Stack>
        <Box>{children}</Box>
      </Stack>
    </Stack>
  );
};

type HeroWithImageBelowProps = Omit<HeroProps, "children"> & {
  src: string;
  srcDark?: string;
  alt: string;
};
export const HeroWithImage = (props: HeroWithImageBelowProps) => {
  const { src, srcDark, alt, height, width, ...heroProps } = props;
  const srcDarkLight = useColorModeValue(src, srcDark ? srcDark : src); //_dark does not work for it

  return (
    <Hero {...heroProps}>
      <Image
        objectFit="cover"
        src={srcDarkLight}
        alt={alt}
        minW={{ base: "unset", lg: "md" }}
        boxShadow="xl"
        overflow="hidden"
      />
    </Hero>
  );
};
